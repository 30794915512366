export const ROUTES = {
  main: '/',
  lineage: '/lineage',
  teachers: '/teachers',
  library: '/library',
  media: '/media',
  feedback: '/feedback',
  online: '/online',
  shop: '/shop',
  teaShop: '/tea-shop',
  contacts: '/contacts',
};

export const URL = {
  youtube: 'https://www.youtube.com/channel/UCJNov9v4MSklmtsLDCkfQow',
  telegram: 'https://t.me/taiheclub',
  zenclass: 'https://taihe.zenclass.ru',
  mailto: 'mailto:taiheclub@yandex.ru',
};

export const VISIBLE_FEEDBACK = 5;

export const BUTTON_TYPE = { link: 'link', button: 'button' };

export const SCREEN_SM = 580;
